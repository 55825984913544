import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

const JsonStructureViewer = ({ data, onElementSelect }) => {
  // Перевірка наявності даних та структури даних
  const layers = data && Array.isArray(data.layer) ? data.layer : []; // Перевірка наявності та формату "layer"
  const bgimg = data && typeof data.bgimg === 'string' ? data.bgimg : ''; // Перевірка наявності "bgimg"
  
  return (
    <List>
      {bgimg && ( // Додаємо перевірку наявності "bgimg" перед рендерингом
        <ListItem button onClick={() => onElementSelect(bgimg, -1)}>
          <ListItemText primary="Background Image" />
        </ListItem>
      )}
      {layers.map((element, index) => (
        <ListItem button key={index} onClick={() => onElementSelect(element, index)}>
          <ListItemText
            primary={element.imgurl ? `Image ${index + 1}` : `Text ${index + 1}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

JsonStructureViewer.propTypes = {
  data: PropTypes.shape({
    bgimg: PropTypes.string,
    layer: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onElementSelect: PropTypes.func.isRequired,
};

export default JsonStructureViewer;


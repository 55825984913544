import React, { useState, useEffect } from 'react';
import { Box, TextField, Select, MenuItem, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';

const SelectCertificateDb = ({ certifiacateList, onLoad, onSave, onSaveAs, onDelete }) => {
  const [selectedCertificate, setSelectedCertificate] = useState(certifiacateList && certifiacateList[0].name);

  useEffect(() => {
    setSelectedCertificate(certifiacateList && certifiacateList[0].name);
  }, [certifiacateList]);

  // Обробник зміни вибору сертифіката
  const handleCertificateChange = (event) => {
    console.log('handleCertificateChange');
    setSelectedCertificate(event.target.value);
    onLoad(event.target.value);
  };

  // Обробник для кнопки "Save"
  const handleSave = () => {
    onSave(selectedCertificate);
  };

  // Обробник для кнопки "Save as"
  const handleSaveAs = () => {
    onSaveAs(selectedCertificate);
  };

  // Обробник для кнопки "Delete"
  const handleDeleteCertificate = () => {
    onDelete(selectedCertificate);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography gutterBottom>Select Certificate</Typography>
      <Select
        value={selectedCertificate}
        onChange={handleCertificateChange}
      >
        {certifiacateList && certifiacateList.map((certItem) => (
          <MenuItem key={certItem.name} value={certItem.name}>
            {certItem.name}
          </MenuItem>
        ))}
      </Select>
      
      <Button onClick={handleSave}>
        Save
      </Button>
      
      {/* <Button onClick={handleSaveAs}>
        Save As
      </Button> */}
      
      <Button onClick={handleDeleteCertificate}>
        Delete
      </Button>
    </Box>
  );
};

SelectCertificateDb.propTypes = {
  certifiacateList: PropTypes.array.isRequired,  // Виправлено тип на array
  onSave: PropTypes.func.isRequired,
  onSaveAs: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SelectCertificateDb;

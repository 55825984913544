import React, { useState, useEffect } from 'react';
import { TextField, Button, InputAdornment, Box } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import PropTypes from 'prop-types';

const GoogleDBAdmin = ({ data, googleData, currLineNumber, onCurrLineNumberChanged, onHandleLoadClick, isLoaded }) => {
    // Локальний стан для зберігання значень полів
    const [localElement, setLocalElement] = useState(data && data.googleDb && {
        text0: data.googleDb.sheetId,
        text1: data.googleDb.range
    });

    useEffect(() => {
        setLocalElement(data && data.googleDb && {
            text0: data.googleDb.sheetId,
            text1: data.googleDb.range
        });
    }, [data]);

    // Обробник зміни полів
    const handleChange = (event) => {
        const { name, value } = event.target;
        setLocalElement((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Обробник зміни currLineNumber
    const handleLineNumberChange = (event) => {
        onCurrLineNumberChanged(Number(event.target.value));
    };

    // Обробник кліку кнопки "Load data"
    const handleClicked = () => {
        onHandleLoadClick(localElement.text0, localElement.text1); // Виклик функції з передачою введених даних
    };

    if (!localElement) return <>GoogleDBAdmin data null</>;
    
    return (
        <>
            {isLoaded && <>isLoaded</>}
            <TextField
                label="Sheet ID"
                name="text0"
                value={localElement.text0}
                onChange={handleChange}
            />
            <TextField
                label="Range"
                name="text1"
                value={localElement.text1}
                onChange={handleChange}
            />
            <TextField
                key='Line Number'
                label={`Line Number (${googleData ? googleData.length : 0})`}
                type="number"
                value={currLineNumber}
                onChange={handleLineNumberChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button 
                                    onClick={() => onCurrLineNumberChanged(currLineNumber + 1)} 
                                    size="small"
                                    sx={{ minWidth: '0' }}
                                >
                                    <ArrowUpward />
                                </Button>
                                <Button 
                                    onClick={() => onCurrLineNumberChanged(currLineNumber - 1)} 
                                    size="small"
                                    sx={{ minWidth: '0' }}
                                >
                                    <ArrowDownward />
                                </Button>
                            </Box>
                        </InputAdornment>
                    ),
                }}
            />
            <Button onClick={handleClicked}>
                Load data
            </Button>
        </>
    );
};

GoogleDBAdmin.propTypes = {
    currLineNumber: PropTypes.number.isRequired,
    onCurrLineNumberChanged: PropTypes.func.isRequired,
    onHandleLoadClick: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    data: PropTypes.object
};

export default GoogleDBAdmin;

import React, { useEffect, useState, useRef } from 'react';
import { Box, Paper, Button } from '@mui/material';
import CertificateMaker from '../modules/CertificateMaker/CertificateMaker';
import AdminCertificatePanel from '../modules/CertificateMaker/AdminCertificatePanel';
//import data from '../modules/CertificateMaker/data/sampleData2';
import html2canvas from 'html2canvas';
import {fetchSpreadsheetData} from '../modules/GoogleSpreadsheet/function/fetchSpreadsheetData';
import {getAllCertificates, updateCertificate} from '../api_sequelize/api_bcCertificate';

const PREFIX = "BrendCenter_CertificateMaker_";

const getInitialZoom = () => {
  const savedZoom = localStorage.getItem(`${PREFIX}zoom`);
  return savedZoom ? Number(savedZoom) : 100;
};

const getInitialTranslate = () => {
  const savedTranslate = localStorage.getItem(`${PREFIX}translate`);
  return savedTranslate ? JSON.parse(savedTranslate) : { x: 0, y: 0 };
};

const getImageSize = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
  
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
  
      img.onerror = (error) => {
        reject(`Помилка завантаження зображення: ${error}`);
      };
    });
};

const CertificateMakerPageGoogle = () => {
    const [bgSize, setBgSize] = useState(null); 
    const [error, setError] = useState(null);

    const [certificateData, setCertificateData] = useState(null);
    const [googleData, setGoogleData] = useState(null);
    const [certifiacateList, setSertifiacateList] = useState(null);
    const [selectedCertifiacate, setSelectedCertifiacate] = useState(null);
    const [currGoogleLineNumber, setCurrGoogleLineNumber] = useState(0);

    const [zoom, setZoom] = useState(getInitialZoom());
    const [dragging, setDragging] = useState(false);
    const [translate, setTranslate] = useState(getInitialTranslate());
    const dragStartRef = useRef({ x: 0, y: 0 });
    const animationFrameId = useRef(null);
    const makerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem(`${PREFIX}zoom`, zoom);
        localStorage.setItem(`${PREFIX}translate`, JSON.stringify(translate));
    }, [zoom, translate]);

    useEffect(() => {
        const fetchImageSize = async () => {
        if (selectedCertifiacate && selectedCertifiacate.json && selectedCertifiacate.json.size === "auto") {
            try {
            const size = await getImageSize(selectedCertifiacate.json.bgimg);
            setBgSize(size);
            } catch (err) {
            setError(err);
            }
        }
        };

        fetchImageSize();
        if (selectedCertifiacate && selectedCertifiacate.json && selectedCertifiacate.json) {
            setCertificateData(selectedCertifiacate.json);
        }
    }, [selectedCertifiacate]);

    useEffect(() => {
        const fetchCertifiacateList = async () => {
            try {
                const data = await getAllCertificates();
                setSertifiacateList(data);
                setSelectedCertifiacate(data && data[0]);
            } catch (err) {
                setError(err);
            }
        };

        fetchCertifiacateList();
    }, []);

    useEffect(() => {
        const fetchGoogleData = async (db) => {
            try {
                const googleData = await fetchSpreadsheetData(db.sheetId, db.range);
                const googleData2 = updateSpecifyFields(googleData, certificateData);
                setGoogleData(googleData2);
            } catch (err) {
                setError(err);
            }
        };

        if (certificateData && certificateData.googleDb)
            fetchGoogleData(certificateData.googleDb);
    }, [certificateData]);

            // certificateData.layer.map((item) => {
        //     const tg = item.text_google.split('#');
        //     const field_name = tg[0];
        //     const tg2 = tg[1].split(';');
        //     const rc = tg2[0];
        //     const rac = tg2[1];
        //     const ic = tg2[2];
        //     item[field_name] = rc ? `RC ${rc}`
        // })
    const updateSpecifyFields = (googleData, certificateData) => {
        googleData.map((item) => {
            Object.entries(item).forEach(([field, value]) => {
                if (field === "Назва Дистрикту та країни для гостей" && value) {
                    item['RClub'] = `${value}`;
                }
                if (field === "Виберіть ваш Сателітний Ротарі Клуб" && value!=="--- Не є членом Сателітного Ротарі Клубу ---") {
                    item['RClub'] = `Satelite RC ${value}`;
                }
                if (field === "Виберіть ваш Ротарі Клуб " && value!=="--- Не є членом Ротарі Клубу ---") {
                    item['RClub'] = `RC ${value}`;
                }
                if (field === "Виберіть ваш  Ротаракт Клуб" && value!=="--- Не є членом Ротаракт Клубу ---") {
                    item['RClub'] = `RAC ${value}`;
                    
                } 
                //console.log(`========== field = `+field+' ====== v='+value);
            });
        
        });
        return googleData;
    };
          

    const onCertifacateChanged = (certname) => {
        console.log('onCertifacateChanged'+certname);
        if (certifiacateList && certname) {
            console.log('onCertifacateChanged select');
            const cert_ = certifiacateList.find(item => item.name === certname) || null;
            setSelectedCertifiacate(cert_)
        }
    };
    const onCertifacateSaved = (certname) => {
        console.log('onCertifacateSaved'+certname);
        if (certifiacateList && certname) {
            console.log('onCertifacateSaved select');
            const cert_ = certifiacateList.find(item => item.name === certname) || null;
            //setSelectedCertifiacate(cert_)
            const { id, ...rest } = cert_;
            updateCertificate(cert_.id, rest);
        }
    };

    const handleElementChange = (updatedData) => {
        setCertificateData(updatedData);
    };

    const handleZoomChange = (e) => {
        const value = Math.min(Math.max(parseInt(e.target.value, 10), 10), 500); // обмеження масштабу від 10% до 500%
        setZoom(value);
    };

    const handleWheel = (e) => {
        if (makerRef.current && makerRef.current.contains(e.target)) {
        if (e.deltaY < 0) {
            setZoom((prevZoom) => Math.min(prevZoom + 10, 500)); // збільшення масштабу
        } else {
            setZoom((prevZoom) => Math.max(prevZoom - 10, 10)); // зменшення масштабу
        }
        }
    };

    const handleMouseDown = (e) => {
        setDragging(true);
        dragStartRef.current = { x: e.clientX - translate.x, y: e.clientY - translate.y };
    };

    const handleMouseMove = (e) => {
        if (dragging && !animationFrameId.current) {
        animationFrameId.current = requestAnimationFrame(() => {
            setTranslate({ x: e.clientX - dragStartRef.current.x, y: e.clientY - dragStartRef.current.y });
            animationFrameId.current = null;
        });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
        if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = null;
        }
    };
    
    const handleDownload = async (num) => {
        if (makerRef.current && bgSize) {
            // Розраховуємо масштаб на основі бажаного розміру
            const scale = bgSize.width / makerRef.current.offsetWidth;
    
            // Застосовуємо масштаб через CSS-трансформацію
            makerRef.current.style.transform = `scale(${scale})`;
            makerRef.current.style.transformOrigin = 'top left';
    
            // Створюємо canvas із масштабованим вмістом
            const canvas = await html2canvas(makerRef.current, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: null,
                logging: true,
                scale: 1, // Тут залишаємо масштаб 1, оскільки ми вже збільшили контент через CSS
                width: bgSize.width, // Використовуємо бажану ширину
                height: bgSize.height, // Використовуємо бажану висоту
            });
    
            // Повертаємо елемент до нормального стану після знімка
            makerRef.current.style.transform = '';
            makerRef.current.style.transformOrigin = '';
    
            const dataUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.download = `certificate_${num+1}.png`;
            link.href = dataUrl;
            link.click();
        }
    };
    
    
    
    const handleDownloadAll = async () => {
        if (!googleData) return;
      
        for (let i = 0; i < googleData.length; i++) {
          setCurrGoogleLineNumber(i); // Оновлюємо стан
      
          // Очікуємо, поки стан буде оновлено
          await new Promise((resolve) => {
            // Використовуємо useEffect для відслідковування зміни стану
            const checkStateUpdate = () => {
              handleDownload(i+1); // Викликаємо завантаження після оновлення стану
              resolve(); // Продовжуємо цикл після завантаження
            };
      
            // Додаємо слухача, який буде чекати на зміни стану
            setTimeout(checkStateUpdate, 500); // Перевіряємо оновлення через 500 мс
          });
        }
      };
      
      
    const onCurrLineNumberChanged = (num) => {
        if (num>=0 && googleData && num<googleData.length)
            setCurrGoogleLineNumber(num);
    }

    //if (error) return <div>{error && error.message}</div>;

    return (
        <Box
        sx={{
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            padding: '20px',
            position: 'relative',
            overflow: 'hidden',
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        >
            {/* selectedCertifiacate={selectedCertifiacate && JSON.stringify(selectedCertifiacate)} */}
            {/* googleData={googleData && JSON.stringify(googleData)} */}
        <Paper
            ref={makerRef}
            elevation={3}
            sx={{
            padding: '20px',
            width: 'fit-content',
            transform: `scale(${zoom / 100}) translate(${translate.x / (zoom / 100)}px, ${translate.y / (zoom / 100)}px)`,
            transformOrigin: 'center',
            transition: 'transform 0.2s',
            cursor: dragging ? 'grabbing' : 'grab',
            padding: 0,
            }}
            onMouseDown={handleMouseDown}
            onWheel={handleWheel}
        >
            <CertificateMaker data={certificateData} bgSize={bgSize} googleLine={googleData && googleData[currGoogleLineNumber]} />
        </Paper>
        <Paper
            elevation={3}
            sx={{
            padding: '20px',
            width: 'fit-content',
            position: 'absolute',
            right: '20px',
            top: '20px',
            maxHeight: 'calc(100vh - 40px)',
            overflowY: 'auto',
            }}
            className="admin-panel"
        >
            <AdminCertificatePanel
            data={certificateData}
            googleData={googleData}
            certifiacateList={certifiacateList}
            onCertifacateChanged={onCertifacateChanged}
            onCertifacateSaved={onCertifacateSaved}
            onElementChange={handleElementChange}
            currLineNumber={currGoogleLineNumber} 
            onCurrLineNumberChanged={onCurrLineNumberChanged}
            zoom={zoom}
            onZoomChange={handleZoomChange} 
            bgSize={bgSize}
            />
            <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{ marginTop: '10px' }}
            >
            Download
            </Button>
            <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadAll}
            sx={{ marginTop: '10px' }}
            >
            Download All
            </Button>
        </Paper>
        </Box>
    );
};

export default CertificateMakerPageGoogle;

import axios from "axios";

const SPREADSHEET_ID = "1X-elpO2J-1PhtAvdA1RyoW6gJA220HAhpiR8mWfjr-o"; // Ваш spreadsheetId
const API_KEY = "AIzaSyBloYHRG1gNWKZvzg6mHcvZFhE9PFHZXLM"; // Ваш Google API ключ

// Функція для отримання даних з Google Sheets
export const fetchSpreadsheetData = async (sheetId=SPREADSHEET_ID,range="sh1!A1:D") => {
    try {
      //const range = "sh1!A1:D"; // Заміни діапазон на потрібний
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}`,
        {
          params: {
            key: API_KEY
          }
        }
      );
  
      const rows = response.data.values;
      if (!rows || rows.length === 0) return []; // Повертає пустий масив, якщо даних немає
  
      const headers = rows[0]; // Перший рядок — це назви полів
      const data = rows.slice(1).map(row => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || ""; // Присвоює значення відповідним полям або пустий рядок
        });
        return obj;
      });
  
      return data; // Повертає масив об'єктів
    } catch (error) {
      console.error("Помилка під час отримання даних:", error);
      throw error;
    }
  };
import React, { useState, useEffect } from 'react';

import Main from '../components/Main';
import OverlayImagesComponent_DB from '../components/AvatarMakerPage/OverlayImagesComponent_DB'; 

import KPI_OVERLAY from '../assets/img/T10_1.png';
import KPI_OVERLAY_2 from '../assets/img/KPI_2324_6.png';
import KPI_GRAY_PEOPLE from '../assets/img/tamplate-overlay.png';

const data = {
    id: 0,
    titele: "Новий Рік 2025",
    year: "24-25",
    arr: [
        {
            eng: 'https://7fc65ea596c38701.cdn.express/secure/b48djo_c5-cQ_csIcLIFaw/clubsitecreator/brendcenter/NewYear2025/eng.png',
            ukr: 'https://7fc65ea596c38701.cdn.express/secure/7lxbdMpxjYTOWlG7wfGZCg/clubsitecreator/brendcenter/NewYear2025/укр.png',
        },
    ]
}

const AvatarMakerPage12 = () => {
    return (
        <>
        <Main>
            <OverlayImagesComponent_DB 
                data={data}
                img={KPI_OVERLAY} 
                img2={KPI_OVERLAY_2} 
                imgGrayPepole={KPI_GRAY_PEOPLE}
                defSize='large'
                cloudFolder="NewYear25"
                needCircle={true} />
        </Main>
        </>
    )
}

export default AvatarMakerPage12;
import React, { useState, useEffect } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDropzone } from 'react-dropzone';
import JsonStructureViewer from './JsonStructureViewer';
import GoogleDBAdmin from './GoogleDBAdmin';
import ElementEditor from './ElementEditor';
import ZoomControls from './ZoomControls';
import SelectCertificateDb from './SelectCertificateDb';

const PREFIX = "BrendCenter_CertificateMaker_";

const AdminCertificatePanel = ({ data, googleData, certifiacateList, onCertifacateChanged, onCertifacateSaved, onElementChange, currLineNumber, onCurrLineNumberChanged, zoom, onZoomChange, bgSize }) => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [expanded, setExpanded] = useState({
    zoomControls: true,
    jsonViewer: true,
    googleDb: true,
    offsetScale: true,
    fontControls: true,
  });

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem(`${PREFIX}accordionState`));
    if (savedState) {
      setExpanded(savedState);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`${PREFIX}accordionState`, JSON.stringify(expanded));
  }, [expanded]);

  const handleLoadClick = (text1, text2) => {
    console.log('Sheet ID:', text1);
    console.log('Range:', text2);
  };

  const handleElementSelect = (element, index) => {
    setSelectedElement({ ...element, index });
  };

  const handleElementUpdate = (updatedElement) => {
    const updatedData = { ...data };
    if (updatedElement.index === -1) {
      updatedData.bgimg = updatedElement.bgimg || updatedElement;
    } else {
      updatedData.layer[updatedElement.index] = updatedElement;
    }
    onElementChange(updatedData);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const handleDrop = (acceptedFiles, name) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (selectedElement.index === -1) {
        handleElementUpdate({ bgimg: reader.result });
      } else {
        handleElementUpdate({ ...selectedElement, [name]: reader.result });
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 'imgurl'),
  });

    const onCertificateLoad = (cert) => {
        onCertifacateChanged(cert);
    };
    const onCertificateSave = (cert) => {
        onCertifacateSaved(cert);
    };
    const onCertificateSaveAs = () => {
    };
    const onCertificateDelete = () => {
    };
  
    const handleBgImageDrop = (acceptedFiles) => handleDrop(acceptedFiles, 'bgimg');

    return (
        <Box sx={{ maxHeight: '100vh', overflowY: 'auto', padding: '10px' }}>
        <Accordion expanded={expanded.zoomControls} onChange={handleAccordionChange('zoomControls')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Select Certificate</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <SelectCertificateDb 
                    certifiacateList={certifiacateList} 
                    onLoad={onCertificateLoad} 
                    onSave={onCertificateSave} 
                    onSaveAs={onCertificateSaveAs} 
                    onDelete={onCertificateDelete}
                />
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.zoomControls} onChange={handleAccordionChange('zoomControls')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Zoom Controls</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ZoomControls zoom={zoom} onZoomChange={onZoomChange} />
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.googleDb} onChange={handleAccordionChange('googleDb')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Google DB</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <GoogleDBAdmin data={data} googleData={googleData} onHandleLoadClick={handleLoadClick} isLoaded={!!googleData} 
                    currLineNumber={currLineNumber} onCurrLineNumberChanged={onCurrLineNumberChanged}
            />
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.jsonViewer} onChange={handleAccordionChange('jsonViewer')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>JSON Viewer</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <JsonStructureViewer data={data} onElementSelect={handleElementSelect} />
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.offsetScale} onChange={handleAccordionChange('offsetScale')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Offset and Scale</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {selectedElement && (
                <ElementEditor
                element={selectedElement}
                onElementUpdate={handleElementUpdate}
                pageSize={bgSize}
                googleLine={googleData && googleData[0]}
                />
            )}
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.fontControls} onChange={handleAccordionChange('fontControls')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Font Controls</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {selectedElement && !selectedElement.imgurl && (
                <ElementEditor
                element={selectedElement}
                onElementUpdate={handleElementUpdate}
                pageSize={bgSize}
                googleLine={googleData && googleData[0]}
                />
            )}
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded.uploadControls} onChange={handleAccordionChange('uploadControls')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Image Upload</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>Upload Background Image</Typography>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Button variant="contained" component="span">
                Select Image
                </Button>
            </div>
            <TextField
                label="Background Image URL"
                fullWidth
                margin="normal"
                value={selectedElement?.bgimg || ''}
                onChange={(e) => handleElementUpdate({ bgimg: e.target.value })}
            />
            <Typography>Upload Element Image</Typography>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Button variant="contained" component="span">
                Select Image
                </Button>
            </div>
            <TextField
                label="Element Image URL"
                fullWidth
                margin="normal"
                value={selectedElement?.imgurl || ''}
                onChange={(e) => handleElementUpdate({ ...selectedElement, imgurl: e.target.value })}
            />
            </AccordionDetails>
        </Accordion>
        </Box>
    );
};

export default AdminCertificatePanel;

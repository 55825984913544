import React from 'react';
import PropTypes from 'prop-types';

const CertificateMaker = ({ data, bgSize, googleLine }) => {
    if (!data || !bgSize) return <>CertificateMaker data null</>;
  return (
    <div
      style={{
        width: bgSize.width,
        height: bgSize.height,
        backgroundImage: `url(${data.bgimg})`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      {data.layer.map((layer, index) => {
        if (layer.imgurl) {
          return (
            <img
              key={index}
              src={layer.imgurl}
              alt=""
              style={{
                position: 'absolute',
                left: `${layer.location.offsetX}px`,
                top: `${layer.location.offsetY}px`,
                transform: `scale(${layer.location.scale})`,
              }}
            />
          );
        }
        if (layer.text0 || layer.text_google) {
          const fontStyle = {
            fontFamily: layer.location['font-family'],
            fontSize: layer.location['font-size'],
            color: layer.location['font-color'],
            fontWeight: layer.location['font-weight'],
            fontStyle: layer.location['font-style'],
            whiteSpace: 'pre-line',
            textAlign: 'center',
          };
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: `${layer.location.offsetX}px`,
                top: `${layer.location.offsetY}px`,
                transform: 'translateX(-50%)',
                width: 'max-content',
              }}
            >
              <div style={fontStyle}>{layer.text_google ? (googleLine ? googleLine[layer.text_google] : 'error') : layer.text0}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

CertificateMaker.propTypes = {
  data: PropTypes.shape({
    bgimg: PropTypes.string.isRequired,
    size: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    layer: PropTypes.arrayOf(
      PropTypes.shape({
        imgurl: PropTypes.string,
        text0: PropTypes.string,
        location: PropTypes.shape({
          offsetX: PropTypes.number,
          offsetXcenter: PropTypes.bool,
          offsetY: PropTypes.number,
          offsetYcenter: PropTypes.bool,
          scale: PropTypes.string,
          'font-family': PropTypes.string,
          'font-size': PropTypes.string,
          'font-color': PropTypes.string,
          'font-weight': PropTypes.string,
          'font-style': PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
};

export default CertificateMaker;

import React, { useEffect, useState } from "react";
import {fetchSpreadsheetData} from './function/fetchSpreadsheetData';
  
  const SpreadsheetDataFetcher = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const loadData = async () => {
        try {
          const jsonData = await fetchSpreadsheetData(); // Викликає функцію для отримання даних
          setData(jsonData);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      loadData();
    }, []);
  
    if (loading) return <div>Завантаження даних...</div>;
    if (error) return <div>Помилка: {error}</div>;
  
    return (
      <div>
        <h3>Дані з Google Spreadsheet:</h3>
        <ul>
          {data.map((row, index) => (
            <li key={index}>{JSON.stringify(row)}</li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default SpreadsheetDataFetcher;
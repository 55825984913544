import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllCertificates = async () => {
    console.log('======== getAllCertificates');
  try {
    const response = await api.get('/bc_certifacates');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch certificates:', error);
    throw error;
  }
};

export const getCertificateById = async (id) => {
  try {
    const response = await api.get(`/bc_certifacate/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch certificate with id ${id}:`, error);
    throw error;
  }
};

export const createCertificate = async (certificateData) => {
  try {
    const response = await api.post('/bc_certifacate', certificateData);
    return response.data;
  } catch (error) {
    console.error('Failed to create certificate:', error);
    throw error;
  }
};

export const updateCertificate = async (id, certificateData) => {
  try {
    const response = await api.put(`/bc_certifacate/${id}`, certificateData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update certificate with id ${id}:`, error);
    throw error;
  }
};

export const deleteCertificate = async (id) => {
  try {
    await api.delete(`/certificate/${id}`);
  } catch (error) {
    console.error(`Failed to delete certificate with id ${id}:`, error);
    throw error;
  }
};
